export const testData = [
  {
    avatar: "/images/testimonial/test1.jpg",
    name: "Michelle Marklin",
    review: "It was my first time coding, and I must say I learned a lot!",
  },
  {
    avatar: "/images/testimonial/test5.jpg",
    name: "William Clark",
    review: " wow! I love the practicality of the coding classes.",
  },
  {
    avatar: "/images/testimonial/test3.jpg",
    name: " Meghan Scott ",
    review:
      "  I love how the tutors are patient and explained to my understanding.",
  },

  {
    avatar: "/images/testimonial/test7.jpg",
    name: "Hamdani Yussif",
    review: "The tutors are always well prepared and ready to deliver",
  },
];
